/* eslint-disable no-unused-vars */
import React, { useCallback, memo, useEffect } from "react";
import styled from "styled-components";
import { BsCircle } from "react-icons/bs";
import { useOrochi } from "../context/OrochiContext";
import { useNavigate } from "react-router-dom";
// import { foodData } from "../data/foodData";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import useElementOnScreen from "../utils/useElementOnScreen";

const Food = memo(({ windowWidth, handleSelectedCategory }) => {
  const [containerRef] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  });
  const { t, i18n } = useTranslation();
  const {
    dispatch,
    state: { menuButtons, foodData },
  } = useOrochi();
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const [ref4, inView4] = useInView();
  const [ref5, inView5] = useInView();
  const [ref6, inView6] = useInView();
  const [ref7, inView7] = useInView();
  const [ref8, inView8] = useInView();
  const [ref9, inView9] = useInView();
  const [ref10, inView10] = useInView();
  const [ref11, inView11] = useInView();
  const [ref12, inView12] = useInView();
  const [ref13, inView13] = useInView();

  const changeBackgroundFn = useCallback(
    (value) => {
      dispatch({ type: "change-menu-bg", payload: value });
    },
    [dispatch]
  );
  const navigate = useNavigate();
  useEffect(() => {
    let views = [
      inView1,
      inView2,
      inView3,
      inView4,
      inView5,
      inView6,
      inView7,
      inView8,
      inView9,
      inView10,
      inView11,
      inView12,
      inView13,
    ];
    views.map((el, ind) => el === true && handleSelectedCategory(ind));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inView1,
    inView2,
    inView3,
    inView4,
    inView5,
    inView6,
    inView7,
    inView8,
    inView9,
    inView10,
    inView11,
    inView12,
    inView13,
  ]);

  // console.log(windowWidth)
  return (
    <Main>
      <section id="starters">
        <div ref={ref1}></div>
        <span>
          {/* <span>
            < ref={containerRef}BsCircle />
          </span> */}
          <h5 ref={containerRef}>
            {i18n.language === "en" ? "Starters" : "Entrées"}
          </h5>
        </span>
        <div>
          {foodData?.map((el) =>
            el.category === "Starters" ? (
              <span
                key={Math.random() * 15000}
                // onMouseOver={() => changeBackgroundFn(`${el.id}`)}
                // onClick={() => navigate(`/menu/${el.id}`)}
              >
                <h6>
                  {i18n.language === "en" ? el.name : el.name_fr} <br />{" "}
                  <p>
                    {i18n.language === "en"
                      ? el.description
                      : el.description_fr}
                  </p>
                </h6>
                <p>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </p>
              </span>
            ) : null
          )}
        </div>
      </section>
      <section id="tacos">
        <div ref={ref2}></div>
        <span>
          {/* <span>
            <BsCircle />
          </span> */}
          <h5 ref={containerRef}>
            {i18n.language === "en" ? "Tacos (3 pcs)" : "Tacos (3 pièces)"}
          </h5>
        </span>
        <div>
          {foodData?.map((el) =>
            el.category === "Tacos (3 pcs)" ? (
              <span
                key={Math.random() * 15000}
                // onMouseOver={() => changeBackgroundFn(`${el.id}`)}
                // onClick={() => navigate(`/menu/${el.id}`)}
              >
                <h6>
                  {i18n.language === "en" ? el.name : el.name_fr} <br />{" "}
                  <p>
                    {i18n.language === "en"
                      ? el.description
                      : el.description_fr}
                  </p>
                </h6>
                <p>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </p>
              </span>
            ) : null
          )}
        </div>
      </section>
      <section id="tataki">
        <div ref={ref3}></div>
        <span>
          {/* <span>
            <BsCircle />
          </span> */}
          <h5 ref={containerRef}>
            {i18n.language === "en"
              ? "Tataki (6 slices)"
              : "Tataki (6 tranches)"}
          </h5>
        </span>
        <div>
          {foodData?.map((el) =>
            el.category === "Tataki (6 slices)" ? (
              <span
                key={Math.random() * 15000}
                // onMouseOver={() => changeBackgroundFn(`${el.id}`)}
                // onClick={() => navigate(`/menu/${el.id}`)}
              >
                <h6>
                  {i18n.language === "en" ? el.name : el.name_fr} <br />{" "}
                  <p>
                    {i18n.language === "en"
                      ? el.description
                      : el.description_fr}
                  </p>
                </h6>
                <p>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </p>
              </span>
            ) : null
          )}
        </div>
      </section>
      <section id="omakase">
        <div ref={ref4}></div>
        <span>
          {/* <span>
            <BsCircle />
          </span> */}
          <h5 ref={containerRef}>
            {i18n.language === "en"
              ? "Platters (omakase)"
              : "Plateaux (omakase)"}
          </h5>
        </span>
        <div>
          {foodData?.map((el) =>
            el.category === "Platters (omakase)" ? (
              <span
                key={Math.random() * 15000}
                // onMouseOver={() => changeBackgroundFn(`${el.id}`)}
                // onClick={() => navigate(`/menu/${el.id}`)}
              >
                <h6>
                  {i18n.language === "en" ? el.name : el.name_fr} <br />{" "}
                  <p>
                    {i18n.language === "en"
                      ? el.description
                      : el.description_fr}
                  </p>
                </h6>
                <div>
                  {
                    // Check if the price is a string that's in the format of an array
                    typeof el.price === "string" && el.price.startsWith("[") ? (
                      JSON.parse(el.price.replace(/'/g, '"')).map(
                        (price, index) => (
                          // Render each price in its own paragraph, which causes line breaks
                          <p key={Math.random() * 15000}>
                            {
                              // Use the appropriate language version of the price
                              i18n.language === "en"
                                ? price
                                : JSON.parse(el.price_fr.replace(/'/g, '"'))[
                                    index
                                  ]
                            }
                          </p>
                        )
                      )
                    ) : Array.isArray(el.price) ? (
                      // If el.price is already an array, map over it and render each price
                      el.price.map((price, index) => (
                        <p key={Math.random() * 15000}>
                          {
                            // Again, choose the language appropriately
                            i18n.language === "en" ? price : el.price_fr[index]
                          }
                        </p>
                      ))
                    ) : (
                      // If it's not a string or array, just display the price as is
                      <p key={Math.random() * 15000}>
                        {i18n.language === "en"
                          ? `$${el.price}`
                          : `${el.price_fr}$`}
                      </p>
                    )
                  }
                </div>
              </span>
            ) : null
          )}
        </div>
      </section>
      <section id="makis">
        <div ref={ref5}></div>
        <span>
          <h5 ref={containerRef}>
            {i18n.language === "en"
              ? "Classic Makis 6 pcs"
              : "Makis Classiques 6 morceaux"}
          </h5>
        </span>
        <div>
          {foodData?.map((el) =>
            el.category === "Classic Makis 6 pcs" ? (
              <span key={Math.random() * 15000}>
                <h6>
                  {i18n.language === "en" ? el.name : el.name_fr} <br />{" "}
                  <p>
                    {i18n.language === "en"
                      ? el.description
                      : el.description_fr}
                  </p>
                </h6>
                <p>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </p>
              </span>
            ) : null
          )}
        </div>
      </section>
      <section id="ura-makis">
        <div ref={ref6}></div>
        <span>
          <h5 ref={containerRef}>
            {i18n.language === "en"
              ? "Ura Makis - 6 pcs"
              : "Ura Makis - 6 morceaux"}
          </h5>
        </span>
        <div>
          {foodData?.map((el) =>
            el.category === "Ura Makis - 6 pcs" ? (
              <span key={Math.random() * 15000}>
                <h6>
                  {i18n.language === "en" ? el.name : el.name_fr} <br />{" "}
                  <p>
                    {i18n.language === "en"
                      ? el.description
                      : el.description_fr}
                  </p>
                </h6>
                <p>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </p>
              </span>
            ) : null
          )}
        </div>
      </section>
      <section id="premium-makis">
        <div ref={ref7}></div>
        <span>
          <h5 ref={containerRef}>
            {i18n.language === "en"
              ? "Premium Makis 8pcs"
              : "Premium Makis 8 morceaux"}
          </h5>
        </span>
        <div>
          {foodData?.map((el) =>
            el.category === "Premium Makis 8pcs" ? (
              <span key={Math.random() * 15000}>
                <h6>
                  {i18n.language === "en" ? el.name : el.name_fr} <br />{" "}
                  <p>
                    {i18n.language === "en"
                      ? el.description
                      : el.description_fr}
                  </p>
                </h6>
                <p>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </p>
              </span>
            ) : null
          )}
        </div>
      </section>
      <section id="kushiyaki">
        <div ref={ref8}></div>
        <span>
          <h5 ref={containerRef}>
            {i18n.language === "en"
              ? "Kushiyaki 2 Skewers"
              : "Kushiyaki 2 Brochettes"}
          </h5>
        </span>
        <div>
          {foodData?.map((el) =>
            el.category === "Kushiyaki 2 Skewers" ? (
              <span key={Math.random() * 15000}>
                <h6>
                  {i18n.language === "en" ? el.name : el.name_fr} <br />{" "}
                  <p>
                    {i18n.language === "en"
                      ? el.description
                      : el.description_fr}
                  </p>
                </h6>
                <p>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </p>
              </span>
            ) : null
          )}
        </div>
      </section>
      <section id="hot-dishes">
        <div ref={ref9}></div>
        <span>
          <h5 ref={containerRef}>
            {i18n.language === "en" ? "Hot Dishes" : "Plats Chauds"}
          </h5>
        </span>
        <div>
          {foodData?.map((el) =>
            el.category === "Hot Dishes" ? (
              <span key={Math.random() * 15000}>
                <h6>
                  {i18n.language === "en" ? el.name : el.name_fr} <br />{" "}
                  <p>
                    {i18n.language === "en"
                      ? el.description
                      : el.description_fr}
                  </p>
                </h6>
                <p>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </p>
              </span>
            ) : null
          )}
        </div>
      </section>
      <section id="omakase">
        <div ref={ref10}></div>
        <span ref={containerRef}>
          <h5>Omakase (5 Course)</h5>
        </span>
        <div>
          {foodData?.map((el) =>
            el.category === "Omakase (5 Course)" ? (
              <span key={Math.random() * 15000}>
                <h6>
                  {i18n.language === "en" ? el.name : el.name_fr} <br />{" "}
                  <p>
                    {i18n.language === "en"
                      ? el.description
                      : el.description_fr}
                  </p>
                </h6>
                <p>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </p>
              </span>
            ) : null
          )}
        </div>
      </section>
      <section id="extras">
        <div ref={ref11}></div>
        <span>
          <h5 ref={containerRef}>
            {i18n.language === "en" ? "Extras" : "Accompagnement"}
          </h5>
        </span>
        <div>
          {foodData?.map((el) =>
            el.category === "Extras" ? (
              <span key={Math.random() * 15000}>
                <h6>{i18n.language === "en" ? el.name : el.name_fr}</h6>
                <p>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </p>
              </span>
            ) : null
          )}
        </div>
      </section>
      <section id="desserts">
        <div ref={ref12}></div>
        <span>
          <h5 ref={containerRef}>
            {i18n.language === "en" ? "Desserts" : "Desserts"}
          </h5>
        </span>
        <div>
          {foodData?.map((el) =>
            el.category === "Desserts" ? (
              <span key={Math.random() * 15000}>
                <h6>
                  {i18n.language === "en" ? el.name : el.name_fr} <br />{" "}
                  <p>
                    {i18n.language === "en"
                      ? el.description
                      : el.description_fr}
                  </p>
                </h6>
                <p>
                  {i18n.language === "en" ? `$${el?.price}` : `${el?.price}$`}
                </p>
              </span>
            ) : null
          )}
        </div>
      </section>
      <section>
        <div ref={ref13}></div>
        <div>
          <a
            href={`/pdf/Drunken-Dragon_Food-Menu_${
              i18n.language === "en" ? "EN" : "FR"
            }.pdf`}
            rel="noopener noreferrer"
            target="_blank"
            ref={containerRef}
          >
            {i18n.language === "en" ? "Menu" : "Menu"}
          </a>
          {/* <a
            href={`/pdf/8.5x11-dd-valentines-menu_${
              i18n.language === "en" ? "EN" : "FR"
            }.pdf`}
            rel="noopener noreferrer"
            target="_blank"
            ref={containerRef}
          >
            {i18n.language === "en" ? "Valentines" : "Saint-Valentin"}
          </a> */}
        </div>
      </section>
    </Main>
  );
});
const Main = styled.main`
  width: 100%;
  height: 100%;
  max-height: calc(80% - 20px);
  scroll-behavior: smooth;
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
  padding-right: 50px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: var(--scroll_track);
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--scroll_thumb);
    border-radius: 10px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--scroll_thumb_hover);
  }
  & > section {
    position: relative;
    & > div:first-child {
      position: absolute;
      height: 100px;
      margin-top: 300px;
    }
    & > span {
      display: flex;
      & > h5 {
        width: 100%;
        display: inline-block;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        margin-top: 7px;
        /* margin-left: 10px; */
        padding-bottom: 20px;
        font-weight: 400;
        font-size: 18px;
        text-transform: uppercase;
      }
      & > span {
        margin-right: 10px;
        font-size: 20px;
        color: var(--silver);
        z-index: 4;
      }
    }
    & > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10%;
      /* margin-left: 40px; */
      & > span {
        /* margin-bottom: 15px; */
        /* cursor: pointer; */
        /* padding-left: 5px; */
        height: auto;
        font-weight: 300;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px;
        & > h6 {
          margin: 5px 0;
          font-weight: 500;
          text-transform: capitalize;
          & > p {
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            margin: 4px 0 12px 0;
          }
        }
        & > p {
          margin: 0;
          font-size: 16px;
          line-height: 30px;
          font-weight: 500;
          text-align: right;
        }
        & > div {
          & > p {
            margin: 0;
            font-size: 16px;
            line-height: 30px;
            font-weight: 500;
            text-align: right;
          }
        }
        /* &:hover {
          border: 1px solid var(--silver);
          border-radius: 10px;
          background-color: rgba(255, 255, 255, 0.7);
          color: black;
          & > h6 {
            font-weight: 300;
          }
          & > p {
            font-size: 16px;
            line-height: 30px;
            font-weight: 300;
          }
        } */
      }
    }
  }
  & > section:last-child {
    & > div:last-child {
      padding-top: 20px;
      display: flex;
      justify-content: center;
      text-align: center;
      & > a {
        color: var(--white);
        text-align: center;
        padding: 5px 0;
        border: 1px solid var(--silver);
        border-radius: 10px;
        transition: 0.3s all;
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        max-width: 240px;
        font-weight: 300;
        &:hover {
          background-color: var(--silver);
          color: #101010;
        }
        @media screen and (max-width: 1050px) {
          /* start of phone and medium tablet styles */
          font-size: 14px;
          padding: 10px 0;
          color: var(--silver);
        }
      }
    }
  }
  @media screen and (min-width: 2000px) {
    & > section {
      position: relative;
      & > div:first-child {
        position: absolute;
        height: 100px;
        margin-top: 800px;
      }
    }
    & > section:last-child {
      & > div {
        margin-bottom: 700px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    /* display: block; */
    gap: 0;
    padding: 8px 8px 0 0;
    position: relative;
    right: 0;
    left: 0;
    height: auto;
    max-height: calc(100% - 140px);
    ::-webkit-scrollbar {
      width: 2px;
    }
    & > section {
      & > span {
        position: relative;
        width: 100%;
        & > h5 {
          padding-bottom: 16px;
          font-weight: 300;
          font-size: 20px;
        }
        & > span {
          position: absolute;
          left: -20px;
          bottom: 18px;
        }
      }
      & > div {
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        margin: 0;
        width: 100%;
        margin-bottom: 45px;
        & > span {
          width: 100%;
          /* padding-left: 5%; */
          margin: 0;
          height: auto;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 8px 0;
          &:hover {
            border: none;
            border-radius: 10px;
            background-color: none;
          }
          h6 {
            margin: 0;
            font-size: 16px;
            line-height: 18px;
            & > p {
              margin-top: 4px;
              font-size: 14px;
              line-height: 16px;
              width: 100%;
            }
          }
          p {
            margin: 0;
            line-height: 18px;
          }
        }
      }
    }
    & > section:last-child {
      & > div:first-child {
        margin-top: 0;
      }
      & > div:last-child {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        z-index: 5;
        position: relative;
      }
    }
  }
`;
export default Food;
